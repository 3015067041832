<mat-form-field [ngClass]="customClasses">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-select
    [formControl]="ngControl.control"
    [placeholder]="placeholder"
    [multiple]="multiple"
  >
    @for (d of data; track d) {
      <mat-option
        [value]="d.value"
        [ngClass]="customClasses"
        >{{ d.name }}</mat-option
      >
    }
  </mat-select>
  <mat-icon
    class="arrow-icon"
    matSuffix
    >keyboard_arrow_down</mat-icon
  >
  @if (ngControl.control.errors; as errors) {
    <mat-error>
      @if (errors['required']) {
        This field is mandatory
      } @else if (errors['isWrong']) {
        The selected version is wrong or does not exist anymore.
      } @else {
        {{ customError }}
      }
    </mat-error>
  }
</mat-form-field>
