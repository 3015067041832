<mat-form-field [ngClass]="{ 'label-custom': label }">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }

  @if (icon) {
    <mat-icon
      matPrefix
      class="icon gray-stroke-400"
      [svgIcon]="icon"
    ></mat-icon>
  }
  @if (currency) {
    <span matTextPrefix>{{ currency }} &nbsp;</span>
  }
  <input
    matInput
    [type]="type"
    [placeholder]="placeholder"
    [formControl]="ngControl.control"
    [attr.min]="type === 'number' ? min : null"
    [attr.max]="type === 'number' ? max : null"
  />
  @if (optional) {
    <span
      matTextSuffix
      class="optional"
      >optional</span
    >
  }
  <ng-content></ng-content>
  @if (ngControl.control.errors; as errors) {
    <mat-error>
      @if (errors['required']) {
        This field is mandatory
      } @else if (errors['minlength']) {
        The minimum length is
        {{ errors['minlength'].requiredLength }} characters.
      } @else if (errors['maxlength']) {
        The maximum length is
        {{ errors['maxlength'].requiredLength }} characters.
      } @else {
        {{ customError }}
      }
    </mat-error>
  }
  @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
  }
</mat-form-field>
