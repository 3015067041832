import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SnackbarModel } from '../models/snackbar.model';

export const SnackbarActions = createActionGroup({
  source: 'Snackbar',
  events: {
    'Open Snackbar': props<SnackbarModel>(),
    'Close Snackbar': emptyProps(),
  },
});
