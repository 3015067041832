import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { IconColorEnum, IconSizeEnum, IconThemeEnum } from './enum/icon.enum';

@Component({
  selector: 'dmc-ng-icon',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent {
  @Input() icon!: string;
  @Input() size!: IconSizeEnum;
  @Input() theme!: IconThemeEnum;
  @Input() color!: IconColorEnum;
}
