import { Injectable, signal, WritableSignal } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  GoogleAuthProvider,
  User as IDUser,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  UserInfo,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MessageService } from '@dmc-ng/notifications';
import { from, Observable, of } from 'rxjs';

import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  user: WritableSignal<User | null> = signal(null);

  constructor(
    private msg: MessageService,
    private auth: Auth,
    private router: Router,
  ) {}

  async initialize(): Promise<void> {
    console.info('IDSvc initializing...');
    let initialized = false;

    return new Promise<void>((resolve) => {
      onAuthStateChanged(this.auth, async (user: IDUser | null) => {
        console.info('onAuthStateChanged...');
        if (user) {
          const tokenRes = await user.getIdTokenResult(false);
          const userToCreate = new User(
            tokenRes.token,
            user.displayName || user.email,
            user.email,
            user.photoURL,
            tokenRes.claims,
            (user as UserInfo).uid,
          );
          this.user.set(userToCreate);
          if (this.router.url === '/login') {
            this.router.navigate(['/']);
          }
        } else {
          this.user.set(null);
        }

        if (!initialized) {
          initialized = true;
          resolve();
        }
      });
    });
  }

  async signinWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<void> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (e) {
      this.msg.push('error while logging with email: ', e);

      throw e;
    }
  }

  async resetPassword(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  async confirmPasswordReset(
    oobCode: string,
    newPassword: string,
  ): Promise<void> {
    await confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  async verifyPasswordResetCode(oobCode: string): Promise<string> {
    return verifyPasswordResetCode(this.auth, oobCode);
  }

  async signOut(): Promise<void> {
    await signOut(this.auth);
    this.user.set(null);
  }

  async signinWithGoogle(): Promise<void> {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        /* "login_hint": "" */
      });

      await signInWithPopup(this.auth, provider);
    } catch (e) {
      this.msg.push('error while logging with google: ', e);

      throw e;
    }
  }

  async signinWithMicrosoft(): Promise<void> {
    try {
      const provider = new OAuthProvider('microsoft.com');
      provider.addScope('mail.read');
      provider.setCustomParameters({
        /* prompt: 'consent', */
        /* login_hint: '' */
      });

      await signInWithPopup(this.auth, provider);
    } catch (e) {
      this.msg.push('error while logging with microsoft: ', e);

      throw e;
    }
  }

  public getIdToken(): Observable<string | null> {
    if (this.auth.currentUser) {
      return from(this.auth.currentUser.getIdToken());
    }
    return of(null);
  }
}
