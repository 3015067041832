import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { COUNTRIES_DB } from './i18n';

export type SelectCountrySupportedLanguages =
  | 'en'
  | 'es'
  | 'fr'
  | 'be'
  | 'de'
  | 'it'
  | 'nl'
  | 'pt';

export const SelectCountryLangToken = new InjectionToken<string>(
  'SelectCountryLangToken',
);

export function provideSelectCountry(
  i18n: SelectCountrySupportedLanguages,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: SelectCountryLangToken,
      useValue: i18n,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue() {
        const iconRegistry = inject(MatIconRegistry);
        const sanitizer = inject(DomSanitizer);
        for (const country of COUNTRIES_DB) {
          const countryAlpha2Code = country.alpha2Code.toLowerCase();
          try {
            iconRegistry.addSvgIcon(
              countryAlpha2Code,
              sanitizer.bypassSecurityTrustResourceUrl(
                `assets/svg-country-flags/svg/${countryAlpha2Code}.svg`,
              ),
            );
          } catch (err) {
            console.error(
              'Error: icon not found for ' + countryAlpha2Code,
              err,
            );
          }
        }
      },
    },
  ]);
}
