import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { distinctUntilChanged } from 'rxjs';

import { SelectCountryLangToken } from './select-country.provider';

export interface Country {
  name?: string;
  alpha2Code: string;
  alpha3Code?: string;
  numericCode?: string;
  callingCode?: string;
}

type CustomOptional<T, K extends keyof T> = Omit<T, K> & Partial<T>;
type CountryOptionalMandatoryAlpha2Code = CustomOptional<
  Country,
  'alpha3Code' | 'name' | 'callingCode' | 'numericCode'
>;

@Component({
  selector: 'dmc-ng-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCountryComponent),
      multi: true,
    },
  ],
})
export class SelectCountryComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() appearance: 'fill' | 'outline' = 'outline';
  @Input() countries: Country[] = [];
  @Input() label?: string;
  @Input() placeHolder = 'Select country';
  @Input() required = false;
  @Input() disabled = false;
  /** @deprecated Use clearable to allow user unselect country.*/
  @Input() nullable = true;
  @Input() readonly = false;
  @Input() tabIndex?: number | string;
  @Input() class?: string;
  @Input() itemsLoadSize = 20;
  @Input() loading?: boolean;
  @Input() showCallingCode = false;
  @Input() excludedCountries: CountryOptionalMandatoryAlpha2Code[] = [];
  @Input() language?: string;
  @Input() name = 'country';
  @Input() error = '';
  @Input() cleareable = false;
  @Input() formControlName?: string;
  // @Input() panelWidth?: string | undefined;
  @Input('value') _value: Country | null = null;
  @Input() extendWidth = false;
  @Input() hint?: string | undefined;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCountrySelected: EventEmitter<Country | null> =
    new EventEmitter<Country | null>();

  _formControl: FormControl<string | null> = new FormControl(
    { value: '', disabled: false },
    this.required ? [Validators.required] : [],
  );
  filteredOptions: Country[] = [];
  loadingDB = false;
  debounceTime = 300;
  filterString = '';

  onChange: any = () => {};
  onTouched: any = () => {};
  debounceTimeout: any;

  // private control = NoopValueAccessorDirective.injectNgControl();
  private control?: AbstractControl;

  constructor(
    @Inject(forwardRef(() => SelectCountryLangToken)) public i18n: string,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private cdRef: ChangeDetectorRef,
  ) {}

  get value(): Country | null {
    return this._value;
  }

  set value(val: Country | null) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  async ngOnInit() {
    if (this.formControlName) {
      const abstractControl = this.controlContainer?.control?.get(
        this.formControlName,
      );
      if (abstractControl) {
        this.control = abstractControl;
        const currentValue = this.control.value?.name;
        this._formControl = new FormControl(
          { value: currentValue, disabled: this.disabled },
          this.control.hasValidator(Validators.required)
            ? [Validators.required]
            : [],
        );
        this.control.valueChanges
          .pipe(distinctUntilChanged()) // Workaround for Angular Issue: https://github.com/angular/angular/issues/12540
          .subscribe((el: Country) => {
            console.log('set cnt', el);
            this._formControl.setValue(this.getValueLabel(el));
            this._applyFilters(el?.name ?? el?.alpha2Code);
            // this.inputChanged(this.getValueLabel(el));
          });
      } else {
        console.warn("Can't find parent FormGroup directive");
        this._formControl = new FormControl(
          { value: null, disabled: this.disabled },
          this.required ? [Validators.required] : [],
        );
      }
    } else {
      this._formControl = new FormControl(
        { value: this.value?.name ?? null, disabled: this.disabled },
        this.required ? [Validators.required] : [],
      );
    }
    this._formControl.valueChanges.subscribe((el) => {
      this.inputChanged(el);
    });

    if (!this.countries.length) {
      this.countries = await this._loadCountriesFromDb();
    }
    this.value =
      this.countries.find(
        (el) =>
          el.alpha2Code == this.value?.alpha2Code &&
          !this.excludedCountries.find(
            (el2) => el2.alpha2Code == el.alpha2Code,
          ),
      ) ?? null;
    this._formControl.setValue(this.getValueLabel(this.value));
    this._applyFilters(this._value?.name);
  }

  ngOnChanges(changes: SimpleChanges) {
    let mustUpdateValueAndFilters = false;
    if (changes['countries'] !== undefined) {
      if (
        !changes['countries']?.currentValue ||
        changes['countries']?.currentValue.length === 0
      ) {
        this._loadCountriesFromDb().then((transCountries) => {
          this.countries = transCountries;
          this._formControl.setValue(this.getValueLabel(this.value));
          this._applyFilters(this._value?.name);
        });
      } else {
        this.countries = changes['countries']?.currentValue ?? [];
        this.optionSelected(this.value?.alpha2Code);
        mustUpdateValueAndFilters = true;
      }
    }
    if (changes['excludedCountries']?.currentValue) {
      this.optionSelected(this.value?.alpha2Code);
      mustUpdateValueAndFilters = true;
    }
    if (
      this.countries &&
      this.countries.length &&
      changes['_value']?.currentValue
    ) {
      const alpha2Code = changes['_value']?.currentValue.alpha2Code;
      this.countries = this.countries.map((country) =>
        country.alpha2Code === alpha2Code
          ? changes['_value']?.currentValue
          : country,
      );
      this.value = this.getSelectedCountry(alpha2Code);
      this._formControl.setValue(this.getValueLabel(this.value));
      if (
        this.value?.alpha2Code !== changes['_value']?.previousValue?.alpha2Code
      ) {
        this.onCountrySelected.emit(this.value);
      }
    }
    if (
      changes['disabled']?.currentValue !== changes['disabled']?.previousValue
    ) {
      this.disabled = changes['disabled']?.currentValue;
      if (this.disabled) {
        this._formControl.disable();
        if (this.control) this.control.disable();
      } else {
        this._formControl.enable();
        if (this.control) this.control.enable();
      }
    }
    if (
      changes['required']?.currentValue !== changes['required']?.previousValue
    ) {
      this.required = changes['required']?.currentValue;
      if (this.required) {
        this._formControl.setValidators([Validators.required]);
        if (this.control) {
          this.control.addValidators([Validators.required]);
        }
      } else {
        this._formControl.setValidators([]);
        if (this.control) {
          this.control.removeValidators([Validators.required]);
        }
      }
      this._formControl.updateValueAndValidity();
      if (this.control) {
        this.control.updateValueAndValidity();
      }
    }
    if (
      changes['appearance']?.currentValue ??
      'outline' !== changes['appearance']?.previousValue
    ) {
      this.appearance = changes['appearance']?.currentValue ?? 'outline';
    }
    if (changes['label']?.currentValue !== changes['label']?.previousValue) {
      this.label = changes['label']?.currentValue;
    }
    if (
      changes['placeHolder']?.currentValue ??
      'Select country' !== changes['placeHolder']?.previousValue
    ) {
      this.placeHolder =
        changes['placeHolder']?.currentValue ?? 'Select country';
    }
    if (changes['class']?.currentValue !== changes['class']?.previousValue) {
      this.class = changes['class']?.currentValue;
    }
    if (
      changes['name']?.currentValue ??
      'country' !== changes['name']?.previousValue
    ) {
      this.name = changes['name']?.currentValue ?? 'country';
    }
    if (changes['error']?.currentValue !== changes['error']?.previousValue) {
      this.error = changes['error']?.currentValue;
    }
    if (changes['hint']?.currentValue !== changes['hint']?.previousValue) {
      this.hint = changes['hint']?.currentValue;
    }
    if (
      changes['tabIndex']?.currentValue !== changes['tabIndex']?.previousValue
    ) {
      this.tabIndex = changes['tabIndex']?.currentValue;
    }
    if (
      changes['loading']?.currentValue !== changes['loading']?.previousValue
    ) {
      this.loading = changes['loading']?.currentValue;
      if (this.loading || this.loadingDB || this.disabled) {
        this._formControl.disable();
      } else {
        this._formControl.enable();
      }
    }
    if (
      changes['itemsLoadSize']?.currentValue !==
      changes['itemsLoadSize']?.previousValue
    ) {
      this.itemsLoadSize = changes['itemsLoadSize']?.currentValue;
      mustUpdateValueAndFilters = true;
    }
    if (
      changes['showCallingCode']?.currentValue !==
      changes['showCallingCode']?.previousValue
    ) {
      this.showCallingCode = changes['showCallingCode']?.currentValue;
      this._formControl.setValue(this.getValueLabel(this.value));
    }
    if (changes['countries']?.currentValue) {
      this.countries = changes['countries'].currentValue;
    }
    if (changes['excludedCountries']?.currentValue) {
      this.excludedCountries = changes['excludedCountries'].currentValue;
    }
    if (
      changes['language']?.currentValue &&
      changes['language'].currentValue !== changes['language'].previousValue
    ) {
      this._loadCountriesFromDb().then((transCountries) => {
        this.countries = transCountries.filter(
          (el) =>
            this.countries.findIndex(
              (el2) => el2.alpha2Code == el.alpha2Code,
            ) >= 0,
        );
        this.value = this.getSelectedCountry(this.value?.alpha2Code ?? '');
        this._formControl.setValue(this.getValueLabel(this.value));
        this._applyFilters(this._value?.name);
      });
    }
    if (mustUpdateValueAndFilters) {
      this._applyFilters(this._value?.name);
      this._formControl.setValue(this.getValueLabel(this.value));
    }
  }

  clear() {
    this.filterString = '';
    this._applyFilters('');
    this.value = null;
    this._formControl.reset();
    if (!this.formControlName) {
      this.onCountrySelected.emit(null);
    } else if (this.control) {
      this.control.reset();
    }
  }

  private getSelectedCountry(alpha2Code: string): Country | null {
    return (
      this.countries.find(
        (el) =>
          el.alpha2Code == alpha2Code &&
          !this.excludedCountries.find(
            (el2) => el2.alpha2Code == el.alpha2Code,
          ),
      ) ?? null
    );
  }

  inputChanged(value?: string | null): void {
    if (value != this.value?.name) {
      if (!value) {
        this.clear();
        return;
      }
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this._applyFilters(value ?? '');
      }, this.debounceTime);
    }
  }

  onOptionsSelected($event: MatAutocompleteSelectedEvent) {
    this.optionSelected($event.option.value);
  }

  private optionSelected(
    value: string | undefined,
    fallback: string | null = null,
  ) {
    const country =
      this.countries.find((country) => country.name === value) ?? null;
    this.filterString = country?.name ?? '';
    if (this.value?.alpha2Code !== country?.alpha2Code) {
      console.log('new value here', country);
      this.value = country;
      this._formControl.setValue(this.getValueLabel(this.value) ?? fallback);
      this.onCountrySelected.emit(this.value);
    }
  }

  writeValue(value: Country | null) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getValueLabel(el: Country | null) {
    if (!el) return '';
    const mainValue = el.name
      ? el.name
      : el.alpha3Code
        ? el.alpha3Code
        : el.alpha2Code ?? '';
    if (this.showCallingCode) {
      return mainValue + (el.callingCode ? ' (' + el.callingCode + ')' : '');
    }
    return mainValue;
  }

  async _loadCountriesFromDb(): Promise<Country[]> {
    this._formControl.disable();
    this.loadingDB = true;
    let translatedCountries = [];
    try {
      translatedCountries = await this._importLang();
    } catch (err) {
      console.error('Error: ' + err);
    }
    this.loadingDB = false;
    if (this.loading || this.loadingDB || this.disabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
    return translatedCountries;
  }

  private _importLang(): Promise<any> {
    const lang = ((this.language || this.i18n) ?? '').toLowerCase();
    switch (lang) {
      case 'fr':
        return import('./i18n/fr')
          .then((result) => result.COUNTRIES_DB_FR)
          .then((y) => y);
      case 'be':
        return import('./i18n/be')
          .then((result) => result.COUNTRIES_DB_BY)
          .then((y) => y);
      case 'de':
        return import('./i18n/de')
          .then((result) => result.COUNTRIES_DB_DE)
          .then((y) => y);
      case 'it':
        return import('./i18n/it')
          .then((result) => result.COUNTRIES_DB_IT)
          .then((y) => y);
      case 'nl':
        return import('./i18n/nl')
          .then((result) => result.COUNTRIES_DB_NL)
          .then((y) => y);
      case 'pt':
        return import('./i18n/pt')
          .then((result) => result.COUNTRIES_DB_PT)
          .then((y) => y);
      case 'es':
        return import('./i18n/es')
          .then((result) => result.COUNTRIES_DB_ES)
          .then((y) => y);
      default:
        return import('./i18n/en')
          .then((result) => result.COUNTRIES_DB)
          .then((y) => y);
    }
  }

  private _applyFilters(value?: string) {
    const filterValue = (value ?? '').toLowerCase();

    if (!filterValue) {
      this.filteredOptions = this.countries.filter(
        (el) =>
          !this.excludedCountries.find(
            (el2) => el2.alpha2Code == el.alpha2Code,
          ),
      );
    } else {
      this.filteredOptions = this.countries.filter(
        (option: Country) =>
          !this.excludedCountries.find(
            (el2) => el2.alpha2Code == option.alpha2Code,
          ) &&
          (option.name?.toLowerCase().includes(filterValue) ||
            option.alpha2Code.toLowerCase().includes(filterValue) ||
            option.alpha3Code?.toLowerCase().includes(filterValue) ||
            this.getValueLabel(option)
              .toLocaleLowerCase()
              .includes(filterValue)),
      );
    }
    if (this.itemsLoadSize) {
      this.filteredOptions = this.filteredOptions.slice(0, this.itemsLoadSize);
    }

    // options in the UI are not updated when this component is used within a host component that uses OnPush
    this.cdRef.markForCheck();
  }

  onBlurInput() {
    const currentInput = this._formControl.value;
    const matchCountry = this.countries.find(
      (cnt) => cnt.name?.toLowerCase() === currentInput?.toLowerCase(),
    );
    this.optionSelected(matchCountry?.name, currentInput);
  }
}
