import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  IconColorEnum,
  IconComponent,
  IconSizeEnum,
  IconThemeEnum,
} from '@dmc-ng/ui/icon';

import { SnackbarModel } from '../models/snackbar.model';

@Component({
  selector: 'dmc-ng-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  imports: [
    MatIconModule,
    CommonModule,
    MatProgressSpinnerModule,
    IconComponent,
    MatButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SnackbarComponent {
  readonly iconSizeEnum: typeof IconSizeEnum = IconSizeEnum;
  readonly iconThemeEnum: typeof IconThemeEnum = IconThemeEnum;
  readonly iconColorEnum: typeof IconColorEnum = IconColorEnum;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarModel) {}
}
