import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { TooltipComponent } from '@dmc-ng/ui/tooltip';
import { debounceTime, startWith } from 'rxjs/operators';

import { FilteredModel } from './model/filtered.model';
import { NoopValueAccessorDirective } from '../accessors/noop-value-accessor.directive';

@Component({
  selector: 'dmc-ng-multi-select-chips',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    TooltipComponent,
  ],
  templateUrl: './multi-select-chips.component.html',
  styleUrls: ['./multi-select-chips.component.scss'],
  hostDirectives: [NoopValueAccessorDirective],
})
export class MultiSelectChipsComponent implements OnChanges, OnInit {
  @ViewChild('autocompleteInput')
  autocompleteInput!: ElementRef<HTMLInputElement>;

  @Input() data!: {
    value: string;
    name: string;
    coordinates?: { latitude: number; longitude: number };
  }[];
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() loading = false;
  @Input() fromApi = false;
  @Input() limit?: number;
  @Input() aria? = 'selection';
  @Input() useValueForChips = false;
  @Input() concatValueName = false;
  @Input() returnOption = false;
  @Output() inputUpdated = new EventEmitter<string>();

  ctrl = new FormControl('', { nonNullable: true });
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedItemsSignal = signal<FilteredModel[]>([]);
  filteredSignal = signal<FilteredModel[]>([]);
  ngControl = NoopValueAccessorDirective.injectNgControl();
  scrollPosition = 0;

  private destroyRef = inject(DestroyRef);

  constructor() {
    this.ctrl.valueChanges
      .pipe(takeUntilDestroyed(), debounceTime(300), startWith(''))
      .subscribe((value) => {
        this.updateFilteredSignal(value);
        this.scrollPosition = 0;
      });
  }

  ngOnInit(): void {
    if (this.ngControl.control.value) {
      this.setValues(this.ngControl.control.value);
    }

    this.ngControl.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedItems) => {
        this.setValues(selectedItems);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.updateFilteredSignal(this.ctrl.value, false);
    }
  }

  remove(item: { value: string; name: string }): void {
    const updatedItems = this.selectedItemsSignal().filter(
      (selectedItem) => selectedItem.value !== item.value,
    );
    this.selectedItemsSignal.set(updatedItems);
    this.updateFilteredSignal(this.ctrl.value, false);
    this.ngControl.control.setValue(
      updatedItems.map((i) =>
        !i.coordinates
          ? this.returnOption
            ? i
            : i.value
          : { value: i.value, name: i.name, coordinates: i.coordinates },
      ),
    );
  }

  add(item: {
    value: string;
    name: string;
    coordinates?: { latitude: number; longitude: number };
  }): void {
    const updatedItems = [
      ...this.selectedItemsSignal(),
      { ...item, checked: true },
    ];
    this.selectedItemsSignal.set(updatedItems);
    this.updateFilteredSignal(this.ctrl.value, false);
    this.ngControl.control.setValue(
      updatedItems.map((i) =>
        !i.coordinates
          ? this.returnOption
            ? i
            : i.value
          : { value: i.value, name: i.name, coordinates: i.coordinates },
      ),
    );
  }

  toggleSelection(
    item: { value: string; name: string },
    withQuery = true,
  ): void {
    const isSelected = this.selectedItemsSignal().some(
      (selectedItem) => selectedItem.value === item.value,
    );

    this.scrollPosition =
      document.querySelector('.autocomplete-list')?.scrollTop || 0;

    if (isSelected) {
      this.remove(item);
    } else {
      this.add(item);
    }

    this.updateFilteredSignal(this.ctrl.value, withQuery);
  }

  onOptionSelected(item: { value: string; name: string }): void {
    this.toggleSelection(item, false);
  }

  focusAutocompleteInput(): void {
    setTimeout(() => {
      if (this.autocompleteInput && this.autocompleteInput.nativeElement) {
        this.autocompleteInput.nativeElement.focus();
      }
    }, 100);
  }

  private updateFilteredSignal(value: string, withQuery = true): void {
    const filterValue = value.toLowerCase();
    let items = this.data;
    if (items) {
      if (!this.fromApi) {
        items = items.filter((option) =>
          option.name.toLowerCase().includes(filterValue),
        );
      } else if (typeof value === 'string' && withQuery) {
        this.inputUpdated.emit(value);
      }

      setTimeout(() => {
        document
          .querySelector('.autocomplete-list')
          ?.scrollTo(0, this.scrollPosition);
      });

      this.filteredSignal.set(
        items.map((option) => ({
          ...option,
          checked: this.selectedItemsSignal().some(
            (selectedItem) => selectedItem.value === option.value,
          ),
        })),
      );
    }
  }

  private setValues(values: any[]): void {
    if (values.length > 0) {
      const filteredData = this.data.filter((item) => {
        if (!item.coordinates) {
          return values.includes(item.value);
        } else {
          return values.some((check) => check.value === item.value);
        }
      });

      if (this.fromApi) {
        this.selectedItemsSignal.set(
          values.map((option) => ({
            ...option,
            checked: true,
          })),
        );

        const existingValues = new Set(
          this.selectedItemsSignal().map((item) => item.value),
        );

        const uniqueFilteredData = filteredData.filter(
          (item) => !existingValues.has(item.value),
        );

        const updatedSelectedItems = [
          ...this.selectedItemsSignal(),
          ...uniqueFilteredData.map((option) => ({
            ...option,
            checked: true,
          })),
        ];

        this.selectedItemsSignal.set(updatedSelectedItems);
        this.updateFilteredSignal('', false);
      } else {
        this.selectedItemsSignal.set(
          filteredData.map((option) => ({
            ...option,
            checked: true,
          })),
        );
      }
      if (!this.fromApi) {
        this.updateFilteredSignal('');
      }
    }

    if (values.length < 1) {
      this.selectedItemsSignal.set([]);
      this.ctrl.setValue('', { emitEvent: false });
      this.updateFilteredSignal('');
    }
  }
}
