import { ParsedToken } from '@angular/fire/auth';

export class User {
  constructor(
    readonly token: string,
    readonly name: string | null,
    readonly email: string | null,
    readonly pictureUrl: string | null,
    readonly claims: ParsedToken,
    readonly id: string,
  ) {}
}
