<mat-form-field class="multi-select-chips sm">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }

  @if (selectedItemsSignal().length === 0) {
    <span class="fake-placeholder">Select {{ placeholder }}</span>
  }

  <div class="chips-input">
    @for (item of selectedItemsSignal(); track item; let index = $index) {
      @if (!limit || (limit && index < limit)) {
        <mat-chip-row
          (removed)="remove(item)"
          [value]="item.value"
          class="rounded custom secondary multi-select-chip sm"
        >
          <dmc-ng-tooltip
            [text]="useValueForChips ? item.value : item.name"
          ></dmc-ng-tooltip>
          <button
            matChipRemove
            [attr.aria-label]="'remove ' + item.name"
          >
            <mat-icon
              class="icon gray-stroke-400"
              svgIcon="x-close"
            ></mat-icon>
          </button>
        </mat-chip-row>
      }
    }

    <input
      #input
      type="text"
      class="fake-input"
      [placeholder]="selectedItemsSignal().length > 0 ? '' : placeholder"
      matInput
      [matAutocomplete]="auto"
    />
  </div>

  <mat-autocomplete
    #auto="matAutocomplete"
    class="multi-select-chips-autocomplete"
    (opened)="focusAutocompleteInput()"
  >
    <div class="input-autocomplete">
      <mat-form-field>
        <mat-icon
          matIconPrefix
          class="icon search-icon gray-stroke-400"
          svgIcon="search-lg"
        ></mat-icon>
        <input
          #autocompleteInput
          type="text"
          [placeholder]="ctrl.value.length > 0 ? '' : 'Search ' + placeholder"
          matInput
          [formControl]="ctrl"
        />
      </mat-form-field>
    </div>
    <div class="autocomplete-list">
      <mat-option class="invisible"></mat-option>

      @if (loading) {
        <mat-option class="multi-select-option loading-option">
          <mat-progress-bar
            color="primary"
            mode="query"
          ></mat-progress-bar>
        </mat-option>
      } @else {
        @if (filteredSignal().length === 0) {
          <mat-option class="no-result">
            No results found, try again
          </mat-option>
        } @else {
          @for (item of filteredSignal(); track item) {
            <mat-option
              class="multi-select-option"
              [value]="item.value"
            >
              <div
                (click)="onOptionSelected(item); $event.stopPropagation()"
                class="click-container"
              >
                <div
                  class="option-container"
                  [ngClass]="{ selected: item.checked }"
                >
                  <mat-checkbox
                    [checked]="item.checked"
                    [disableRipple]="true"
                    color="primary"
                    class="multi-select"
                  >
                    <dmc-ng-tooltip
                      [text]="item.name"
                      [width]="'100%'"
                      [htmlText]="
                        concatValueName
                          ? '<span class=\'value-label\'>' +
                            item.value +
                            '.</span> ' +
                            item.name
                          : undefined
                      "
                    ></dmc-ng-tooltip>
                  </mat-checkbox>
                </div>
              </div>
            </mat-option>
          }
        }
      }
    </div>
  </mat-autocomplete>

  <div
    matSuffix
    class="suffix"
  >
    @if (limit && selectedItemsSignal().length > limit) {
      and more...
    }
    <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
  </div>
</mat-form-field>
