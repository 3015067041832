import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  ChangeDetectionStrategy,
  signal,
  AfterViewChecked,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TooltipColorEnum } from './models/enum/tooltip-color.enum';
import { TooltipPositionEnum } from './models/enum/tooltip-position.enum';

@Component({
  selector: 'dmc-ng-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatTooltipModule],
  standalone: true,
})
export class TooltipComponent implements AfterViewChecked {
  @ViewChild('textElement') textElement: ElementRef | null = null;
  @Input() text!: string;
  @Input() htmlText?: string;
  @Input() width?: string;
  @Input() maxWidth?: string;
  @Input() position: TooltipPositionEnum = TooltipPositionEnum.Above;
  @Input() color: TooltipColorEnum = TooltipColorEnum.Black;

  isTextTruncated = signal(false);

  constructor(private sanitizer: DomSanitizer) {}

  get sanitizedText(): SafeHtml | undefined {
    if (this.htmlText) {
      return this.sanitizer.bypassSecurityTrustHtml(this.htmlText);
    }
    return undefined;
  }

  ngAfterViewChecked(): void {
    this.checkTextOverflow();
  }

  checkTextOverflow(): void {
    if (this.textElement) {
      const element = this.textElement.nativeElement;
      this.isTextTruncated.set(element.scrollWidth > element.clientWidth);
    }
  }
}
