<div
  class="logo-container"
  [ngClass]="[size, theme, color]"
>
  <mat-icon
    class="icon"
    [ngClass]="[size, theme, color]"
    [svgIcon]="icon"
  ></mat-icon>
</div>
