<mat-form-field
  [appearance]="appearance"
  [class.d-block]="extendWidth"
>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input
    autocomplete="off"
    [class]="class"
    [matAutocomplete]="countryAutocomplete"
    [placeholder]="placeHolder"
    [readonly]="readonly"
    [tabIndex]="tabIndex"
    [formControl]="_formControl"
    [name]="name"
    (blur)="onBlurInput()"
    aria-label="country"
    matInput
    type="text"
  />
  @if (loadingDB || loading) {
    <mat-progress-bar mode="buffer"></mat-progress-bar>
  }
  <!--    [panelWidth]="panelWidth"-->
  <mat-autocomplete
    #countryAutocomplete="matAutocomplete"
    (optionSelected)="onOptionsSelected($event)"
  >
    @for (country of filteredOptions; track country) {
      <mat-option
        [value]="country.name"
        class="select-country-option"
      >
        <div class="d-flex">
          <mat-icon [svgIcon]="country.alpha2Code.toLowerCase()"></mat-icon>
          <small
            class="country-list-item"
            [title]="getValueLabel(country)"
            >{{ getValueLabel(country) }}</small
          >
        </div>
      </mat-option>
    }
  </mat-autocomplete>
  <div
    matSuffix
    class="mr-1"
  >
    @if (value) {
      <mat-icon
        [svgIcon]="value.alpha2Code.toLowerCase()"
        class="ml-1 s-20 secondary-text"
      ></mat-icon>
    }
    @if (cleareable && !!value) {
      <mat-icon
        class="ml-1 cursor-pointer pos-rel"
        (click)="clear()"
        >cancel</mat-icon
      >
    }
  </div>
  @if (_formControl && _formControl.invalid) {
    <mat-error>{{ error }}</mat-error>
  }
  @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
  }
</mat-form-field>
