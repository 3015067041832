import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { SnackbarActions } from './snackbar.actions';
import { SnackbarComponent } from '../components/snackbar.component';
import { SnackbarModel } from '../models/snackbar.model';

@Injectable()
export class SnackbarEffects {
  closeSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SnackbarActions.closeSnackbar),
        tap(() => this.matSnackBar.dismiss()),
      ),
    { dispatch: false },
  );

  showSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SnackbarActions.openSnackbar),
        tap((payload: Partial<SnackbarModel>) =>
          this.matSnackBar.openFromComponent(SnackbarComponent, {
            data: {
              title: payload.title,
              message: payload.message,
              action: payload.action,
              icon: payload.icon,
              iconColor: payload.iconColor,
            },
            duration: payload.config?.duration,
            horizontalPosition: payload.config?.horizontalPosition,
            verticalPosition: payload.config?.verticalPosition,
            panelClass: payload.config?.panelClass,
          }),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private matSnackBar: MatSnackBar,
  ) {}
}
