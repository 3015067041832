export const PHONE_COMPLETE_REGEX = /^\+33[0-9]{9}$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// eslint-disable-next-line
export const URL_REGEX =
  /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
export const DOMAIN_REGEX =
  /^(?:[a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(?:\.[a-zA-Z]{2})?$/;
export const ZIPCODE_REGEX = /^[0-9]{5}$/;
export const INSEE_CODE_REGEX = /^(?:2A|2B|\d{2})\d{3}$/;
export const IRIS_CODE_REGEX = /^(?:2A|2B|\d{2})\d{7}$/;
export const STOP_REGEX = /^STOP\s(au\s)?\d{5}$/;
export const SENDER_REGEX = /^[a-zA-Z0-9]+$/;
export const SMS_CONTENT_REGEX = /(^|\s)\$\{url}(\s|$)/;
export const UPPER_CASE_REGEX = /[A-Z]/;
export const LOWER_CASE_REGEX = /[a-z]/;
export const DIGIT_REGEX = /\d/;
export const SPECIAL_CHAR_REGEX = /[\W_]/;
export const GSM_7_CHAR_REGEX =
  /^[A-Za-z0-9:;<>?¡¿!"#¤%&'()*+,./ÄÖÑÜ§äöñüà@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ€{}|[\]\\\r\n -]*$/;
export const GSM_7_TWO_SEPTET_CHAR_REGEX = /[€{}|[\]\\]/g;
export const DECIMAL_REGEX = /^\d+(\.\d{1,2})?$/;
export const POI_TAG_REGEX = /^[a-z][a-z0-9-]+$/;
