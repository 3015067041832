import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFromValue',
  standalone: true,
})
export class NameFromValuePipe implements PipeTransform {
  transform(
    items: { value: string; name: string }[] | null,
    targetValue: string,
    concat = false,
  ): string {
    const foundItem = items?.find((item) => item.value === targetValue);
    if (!foundItem) {
      return '';
    }
    if (concat) {
      return `${foundItem.value} - ${foundItem.name}`;
    } else {
      return foundItem.name;
    }
  }
}
