<div
  class="ellipsis-container"
  [style.width]="width"
  [style.maxWidth]="maxWidth"
  [matTooltip]="isTextTruncated() ? text : ''"
  [matTooltipPosition]="position"
  [matTooltipClass]="[position, color]"
  #tooltip="matTooltip"
  #textElement
>
  @if (sanitizedText) {
    <span [innerHTML]="sanitizedText"></span>
  } @else {
    {{ text }}
  }
</div>
