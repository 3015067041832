export enum IconThemeEnum {
  LightCircleOutline = 'light-circle-outline',
  LightCircle = 'light-circle',
  LightSquare = 'light-square',
  MidSquare = 'mid-square',
  DarkSquare = 'dark-square',
  Modern = 'modern',
}

export enum IconColorEnum {
  Primary = 'primary',
  Gray = 'gray',
  White = 'white',
  Brand = 'brand',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum IconSizeEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
