import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { NoopValueAccessorDirective } from '../accessors/noop-value-accessor.directive';

@Component({
  selector: 'dmc-ng-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  hostDirectives: [NoopValueAccessorDirective],
})
export class InputComponent {
  @Input() type: 'text' | 'email' | 'password' | 'number' = 'text';
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() customError?: string;
  @Input() optional = false;
  @Input() min?: number;
  @Input() max?: number;
  @Input() hint?: string;
  @Input() icon?: string;
  @Input() currency?: string;

  ngControl = NoopValueAccessorDirective.injectNgControl();
}
