<div class="custom-snack-bar-container">
  <button
    mat-icon-button
    aria-label="Close button"
    (click)="data.action?.clickOnCancel()"
    class="icon-button snackbar-close small"
  >
    <mat-icon
      class="icon gray-stroke-400"
      svgIcon="x-close"
    ></mat-icon>
  </button>
  <div class="content">
    <div
      class="icon-title"
      [ngClass]="{ 'with-message': data.message }"
    >
      <dmc-ng-icon
        [color]="data.iconColor"
        [theme]="iconThemeEnum.LightCircleOutline"
        [size]="iconSizeEnum.SM"
        [icon]="data.icon"
      ></dmc-ng-icon>
      <span>{{ data.title }}</span>
    </div>
    @if (data.message) {
      <span class="custom-snack-bar-message">
        {{ data.message }}
      </span>
    }
  </div>

  @if (data.action) {
    <button
      mat-button
      (click)="data.action.clickOnAction()"
      [class]="data.action.class"
    >
      {{ data.action.label }}
    </button>
  }
</div>
