import { IconColorEnum } from '@dmc-ng/ui/icon';

import { SnackbarTypeEnum } from '../models/enum/snackbar-type.enum';
import { SnackbarActionsModel } from '../models/snackbar-actions.model';
import { SnackbarModel } from '../models/snackbar.model';

export abstract class SnackbarUtils {
  static createSnackbarOptions(
    title: string,
    iconColor: IconColorEnum,
    action: SnackbarActionsModel,
    message?: string,
  ): SnackbarModel {
    return {
      title,
      iconColor,
      action,
      message,
      icon: 'alert-circle',
      config: {
        duration: 5000,
      },
    };
  }

  static getIconColorFromSnackbarTypeEnum(
    type: SnackbarTypeEnum,
  ): IconColorEnum {
    const mapping: { [key in SnackbarTypeEnum]: IconColorEnum } = {
      [SnackbarTypeEnum.Success]: IconColorEnum.Success,
      [SnackbarTypeEnum.Warning]: IconColorEnum.Warning,
      [SnackbarTypeEnum.Error]: IconColorEnum.Error,
      [SnackbarTypeEnum.Information]: IconColorEnum.Brand,
    };
    return mapping[type];
  }
}
