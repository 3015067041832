import { Injectable } from '@angular/core';
import {
  SnackbarActions,
  SnackbarTypeEnum,
  SnackbarUtils,
} from '@dmc-ng/ui/snackbar';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private store: Store) {}

  push(msg: string, ...p: any[]): void {
    this.store.dispatch(
      SnackbarActions.openSnackbar({
        title: msg.charAt(0).toUpperCase() + msg.slice(1),
        message: p.join(' '),
        icon: 'alert-circle',
        iconColor: SnackbarUtils.getIconColorFromSnackbarTypeEnum(
          SnackbarTypeEnum.Error,
        ),
        config: {
          duration: 5000,
          horizontalPosition: 'right',
        },
      }),
    );
  }
}
