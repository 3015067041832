import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apiToSelect',
  standalone: true,
})
export class ApiToSelectPipe implements PipeTransform {
  transform(
    items:
      | {
          id: string;
          label: string;
          coordinates?: { latitude: number; longitude: number };
        }[]
      | null,
    concatValue = false,
  ): {
    value: string;
    name: string;
    coordinates?: { latitude: number; longitude: number };
  }[] {
    if (!items) {
      return [];
    }
    return items.map((item) => {
      if (item.coordinates) {
        return {
          value: item.id,
          name: concatValue
            ? `${item.id.toUpperCase()} - ${item.label}`
            : item.label,
          coordinates: item.coordinates,
        };
      } else {
        return {
          value: item.id,
          name: concatValue
            ? `${item.id.toUpperCase()} - ${item.label}`
            : item.label,
        };
      }
    });
  }
}
