import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { NoopValueAccessorDirective } from '../accessors/noop-value-accessor.directive';

@Component({
  selector: 'dmc-ng-select',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  hostDirectives: [NoopValueAccessorDirective],
})
export class SelectComponent {
  @Input() data!: { value: string; name: string }[];
  @Input() placeholder = '';
  @Input() label?: string;
  @Input() customError?: string;
  @Input() multiple = false;
  @Input() customClasses?: string;

  ngControl = NoopValueAccessorDirective.injectNgControl();
}
